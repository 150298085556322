import { ELanguage } from '@ts/enums/app.enum.js';
import { getStartingLocale } from '@h/locales';
import ru from './locales/ru.json';
import en from './locales/en.json';

export default defineI18nConfig(() => ({
    fallbackLocale: ELanguage.RU,
    legacy: false,
    globalInjection: true,
    locale: getStartingLocale(),
    messages: {
        ru,
        en,
    },
}));
